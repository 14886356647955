<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">档案管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">一企一档列表</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">每期档案</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="名称" class="searchboxItem ci-full">
              <span class="itemLabel">名称:</span>
              <el-input
                v-model="phaesName"
                type="text"
                size="small"
                placeholder="请输入名称"
                clearable
              />
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="projectName"
                type="text"
                size="small"
                placeholder="请输入班级名称"
                clearable
              />
            </div>
            <div title="企业名称" class="searchboxItem ci-full">
              <span class="itemLabel">企业名称:</span>
              <el-input
                v-model="enterpriseName"
                type="text"
                size="small"
                placeholder="请输入企业名称"
                clearable
              />
            </div>
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
                width="100px"
              />
              <el-table-column
                label="名称"
                align="left"
                prop="phaesName"
                show-overflow-tooltip
              />
              <el-table-column
                label="日期"
                align="left"
                prop="selectDate"
                show-overflow-tooltip
              />
              <el-table-column
                label="班级名称"
                align="left"
                prop="projectName"
                show-overflow-tooltip
              />
              <el-table-column
                label="企业名称"
                align="left"
                prop="enterpriseName"
                show-overflow-tooltip
              />
              <el-table-column label="操作" align="center" width="180px">
                <div slot-scope="scope" class="flexcc">
                  
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handlCatalogueOfArchives(scope.row)"
                    >档案目录</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "ArchivesOfEachIssueJg",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      phaesName: "",
      projectName: "",
      enterpriseName: this.$route.query.enterpriseName, //题库名称
     
    };
  },
  created() {
  },
  computed: {},
  methods: {
    /* 获取列表数据 */
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.enterpriseName) {
        params.enterpriseName = this.enterpriseName;
      }
      if (this.enterpriseId) {
        params.enterpriseId = this.enterpriseId;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.phaesName) {
        params.phaesName = this.phaesName;
      }
      this.doFetch({
        url: "/eighteenEnterprise/enterprisePhase/page",
        params,
        pageNum,
      });
    },
    /* 档案目录 */
    handlCatalogueOfArchives(row) {
      this.$router.push({
        path: "/web/OneDateOfCatalogJg",
        query: {
          payload: JSON.stringify(row),
        },
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 2.55) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        } else {
          this.getData();
        }
        // if (oldVal.query.stu == "add") {
        //   (this.recommendName = ""),
        //     (this.areaId = ""),
        //     (this.terminals = ""),
        //     (this.pageNum = 1);
        //   this.getData(-1);
        // }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less"></style>
